<template>
    <div v-show="visible" class="scroll-indicator">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </template>
  
  <script>
  import { ref, onUnmounted, watch } from 'vue';
  
  export default {
    name: 'ScrollIndicator',
    props: {
      mainContent: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const visible = ref(true);
  
      const handleScroll = () => {
        if (props.mainContent.scrollTop > 20) {
          visible.value = false;
        }
      };
  
      watch(() => props.mainContent, (newValue) => {
        if (newValue) {
          newValue.addEventListener('scroll', handleScroll);
        }
      }, { immediate: true });
  
      onUnmounted(() => {
        if (props.mainContent) {
          props.mainContent.removeEventListener('scroll', handleScroll);
        }
      });
  
      return { visible };
    }
  }
  </script>
  
  <style scoped>
  .scroll-indicator {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    animation: bounce 2s infinite;
    z-index: 1000;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0) translateX(-50%);
    }
    40% {
      transform: translateY(-10px) translateX(-50%);
    }
    60% {
      transform: translateY(-5px) translateX(-50%);
    }
  }
  </style>
<template>
  <div class="digital-twin">
    <h1>Digital Twin</h1>
    <p>Hi there! I'm Vincent's digital twin - here to chat about my work and projects. Curious about my experience as a Machine Learning Engineer? Just ask!</p>
    <p>You can talk to me directly via voice, leave a quick message, or even set up a time to chat more. Let's connect!</p>
  </div>
</template>

<script>
export default {
  name: 'DigitalTwin'
};
</script>

<style scoped>
.digital-twin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
}
</style>
<template>
  <div class="contact">
    <h1>Contact</h1>
    <p>Feel free to reach out to me through any of the following methods:</p>
    
    <div class="contact-info">
      <!-- LinkedIn -->
      <div class="contact-item">
        <a href="https://www.linkedin.com/in/kuizong" target="_blank" rel="noopener noreferrer">
          <i class="fab fa-linkedin"></i> LinkedIn
        </a>
      </div>

      <!-- Phone Number -->
      <div class="contact-item">
        <i class="fas fa-phone"></i> <a href="tel:+61420680108">+61 420 680 108</a>
      </div>

      <!-- Email -->
      <div class="contact-item">
        <i class="fas fa-envelope"></i> <a href="mailto:wukuizongvincent@gmail.com">wukuizongvincent@gmail.com</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactMe'
}
</script>

<style scoped>
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 80vh;
  padding: 60px 20px;
  box-sizing: border-box;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.contact-info {
  margin-top: 20px;
  width: 100%;
}

.contact-item {
  margin: 10px 0;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-item i {
  margin-right: 10px;
  color: #0c42c8; /* 图标颜色 */
}

.contact-item a {
  text-decoration: none;
  color: #000;
}

.contact-item a:hover {
  text-decoration: underline;
}
</style>

<template>
    <div class="microphone-container">
      <div class="microphone-wrapper">
        <div class="microphone-circle">
          <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="face">
            <rect width="100" height="100" fill="none"/>
            
            <!-- eye -->
            <rect class="eye" x="32" y="30" width="8" height="25" rx="4" ry="4" fill="black"/>
            <rect class="eye" x="60" y="30" width="8" height="25" rx="4" ry="4" fill="black"/>
  
            <!-- mouth -->
            <path d="M30 70q20 10 40 0" fill="none" stroke="black" stroke-width="4" stroke-linecap="round"/>
          </svg>
        </div>
        <div class="connection-status">{{ connectionStatus }}</div>
        <div class="volume-bars">
          <div v-for="i in 10" :key="i" class="volume-bar" :style="getMicBarStyle(i)"></div>
        </div>

        <button @click="toggleConnection" class="toggle-button mobile-button" :class="{ 'button-clicked': isClicked }">
          {{ isConnected ? 'Stop Chat' : 'Start Chat' }}
        </button>
      </div>
  
      <button @click="toggleConnection" class="toggle-button desktop-button" :class="{ 'button-clicked': isClicked }">
        {{ isConnected ? 'Stop Chat' : 'Start Chat' }}
      </button>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import { ref, onMounted, onUnmounted } from 'vue';
  import { Room, RoomEvent, createLocalAudioTrack, DataPacket_Kind } from 'livekit-client';
  
  export default {
    name: 'MicrophoneButton',
    emits: ['sectionChange'],
    
    setup(props, { emit }) {
      const isConnected = ref(false);
      const room = ref(null);
      const connectionStatus = ref('Disconnected');
      const localAudioTrack = ref(null);
      const micActivity = ref(0);
      const isClicked = ref(false);
      let audioContext = null;
      let micAnalyser = null;
      let updateActivityAnimationFrame = null;
      let lastMicData = new Uint8Array(128);
      let blinkInterval = null;
  
      const getMicBarStyle = (index) => ({
        height: `${index * 10}%`,
        opacity: index <= Math.ceil(micActivity.value / 10) ? 1 : 0.2
      });
  
      const blink = () => {
        const eyes = document.querySelectorAll('.eye');
        eyes.forEach(eye => {
          const originalHeight = eye.getAttribute('height');
          const originalY = eye.getAttribute('y');
          
          eye.setAttribute('height', '5');
          eye.setAttribute('y', '45');
          
          setTimeout(() => {
            eye.setAttribute('height', originalHeight);
            eye.setAttribute('y', originalY);
          }, 100);
        });
      };
  
      const startBlinking = () => {
        if (blinkInterval) clearInterval(blinkInterval);
        blinkInterval = setInterval(() => {
          blink();
        }, 7000);
      };
  
      const stopBlinking = () => {
        if (blinkInterval) {
          clearInterval(blinkInterval);
          blinkInterval = null;
        }
      };
  
      const connectToRoom = async () => {
        try {
          connectionStatus.value = 'Connecting...';
          const response = await fetch(`${process.env.VUE_APP_CONNECT_URL}`);
          const { token } = await response.json();
  
          room.value = new Room();
          
          room.value.on(RoomEvent.TrackSubscribed, handleTrackSubscribed);
          room.value.on(RoomEvent.TrackUnsubscribed, handleTrackUnsubscribed);
          
          room.value.on(RoomEvent.DataReceived, handleDataReceived);
          
          await room.value.connect(process.env.VUE_APP_LIVEKIT_URL, token);
  
          room.value.on('disconnected', () => {
            isConnected.value = false;
            connectionStatus.value = 'Disconnected';
            stopBlinking();
          });
  
          isConnected.value = true;
          connectionStatus.value = 'Connected';
          startBlinking();
  
          await enableAudio();
        } catch (error) {
          console.error('Failed to connect:', error);
          connectionStatus.value = 'Connection failed';
        }
      };
  
      const enableAudio = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          localAudioTrack.value = await createLocalAudioTrack({
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true
          });
          
          await room.value.localParticipant.publishTrack(localAudioTrack.value);
          
          audioContext = new AudioContext();
          
          // Setup microphone activity monitoring
          micAnalyser = audioContext.createAnalyser();
          const micSource = audioContext.createMediaStreamSource(stream);
          micSource.connect(micAnalyser);
          micAnalyser.fftSize = 256;
  
          const updateActivity = () => {
            if (micAnalyser) {
              const micDataArray = new Uint8Array(micAnalyser.frequencyBinCount);
              micAnalyser.getByteFrequencyData(micDataArray);
              const micDelta = calculateDelta(micDataArray, lastMicData);
              micActivity.value = Math.min(100, micActivity.value + micDelta);
              micActivity.value = Math.max(0, micActivity.value - 2); // Decay
              lastMicData.set(micDataArray);
            }
  
            console.log('Mic Activity:', micActivity.value);
            updateActivityAnimationFrame = requestAnimationFrame(updateActivity);
          };
          updateActivity();
  
        } catch (error) {
          console.error('Failed to enable audio:', error);
        }
      };
  
      const calculateDelta = (newData, oldData) => {
        let totalDelta = 0;
        for (let i = 0; i < newData.length; i++) {
          totalDelta += Math.abs(newData[i] - oldData[i]);
        }
        return totalDelta / newData.length;
      };
  
      const handleTrackSubscribed = (track, publication, participant) => {
        if (track.kind === 'audio') {
          const element = track.attach();
          document.body.appendChild(element);
          console.log('Remote audio track subscribed and attached');
        }
      };
  
      const handleTrackUnsubscribed = (track, publication, participant) => {
        if (track.kind === 'audio') {
          track.detach();
          console.log('Remote audio track unsubscribed and detached');
        }
      };
  
      const handleDataReceived = (payload, participant, kind) => {
        const decoder = new TextDecoder();
        let strData = decoder.decode(payload);
        console.log('Received data:', strData);
        try {
            // Parse the received JSON string
            const parsedData = JSON.parse(strData);
            console.log('Parsed data:', parsedData);

            // Check if the parsed data has a 'message' property containing the 'section'
            if (parsedData && parsedData.message && parsedData.message.section) {
            emit('sectionChange', parsedData.message.section);
            }
        } catch (error) {
            console.error('Error parsing received data:', error);
            console.log('Received raw data:', strData);
        }
        };
  
      const disconnectFromRoom = async () => {
        if (room.value) {
          if (localAudioTrack.value) {
            await room.value.localParticipant.unpublishTrack(localAudioTrack.value);
            localAudioTrack.value.stop();
            localAudioTrack.value = null;
          }
          
          await room.value.disconnect();
          room.value = null;
        }
        isConnected.value = false;
        connectionStatus.value = 'Disconnected';
        stopBlinking();
  
        if (updateActivityAnimationFrame) {
          cancelAnimationFrame(updateActivityAnimationFrame);
          updateActivityAnimationFrame = null;
        }
        if (audioContext) {
          await audioContext.close();
          audioContext = null;
          micAnalyser = null;
        }
        
        micActivity.value = 0;
      };
  
      const toggleConnection = () => {
        isClicked.value = true;
        setTimeout(() => {
          isClicked.value = false;
        }, 200);
  
        if (isConnected.value) {
          disconnectFromRoom();
        } else {
          connectToRoom();
        }
      };
  
      onMounted(() => {
        startBlinking();
      });
  
      onUnmounted(() => {
        disconnectFromRoom();
        stopBlinking();
      });
  
      return {
        isConnected,
        toggleConnection,
        connectionStatus,
        getMicBarStyle,
        isClicked,
      };
    }
  };
  </script>
  
  <style scoped>
  .microphone-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 15vh;
  }
  
  @keyframes moveFace {
    0%, 100% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(1px, -1px);
    }
  }
  
  .microphone-wrapper {
    width: 100%;
    aspect-ratio: 9/13;
    border: 2px solid black;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: inherit;
  }
  
  .microphone-circle {
    width: 10vh;
    height: 10vh;
    border: 2px solid black;
    border-radius: 50%;
    margin-top: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .microphone-circle svg {
    width: 80%;
    height: 80%;
  }
  
  .face {
    animation: moveFace 5s ease-in-out infinite;
  }
  
  .connection-status {
    margin: 10px 0;
    font-size: 1rem;
  }
  
  .volume-bars {
    display: flex;
    justify-content: space-between;
    width: 70%;
    height: 10vh;
    margin-top: 1vh;
    margin-bottom: 20px;
  }
  
  .volume-bar {
    width: 8px;
    border: #000000 2px solid;
    border-radius: 5px;
    transition: height 0.1s ease, opacity 0.1s ease;
  }
  
  .toggle-button {
    display: none;
    }
  
  .toggle-button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    border: black 2px solid;
    cursor: pointer;
    background: None;
    transition: transform 0.2s, background-color 0.2s;
  }
  
  .toggle-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .button-clicked {
    transform: scale(0.95);
    background-color: rgba(0, 0, 0, 0.2);
  }
  
  @media (min-width: 768px) {
  .desktop-button {
    display: block;
  }
  }

  @media (max-width: 767px) {
  .mobile-button {
    display: block; 
    color: black;
  }
  .microphone-wrapper{
    border: none;
  }
  }
  </style>
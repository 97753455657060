import { createRouter, createWebHistory } from 'vue-router'
import DigitalTwin from './components/DigitalTwin.vue'
import AboutMe from './components/AboutMe.vue'
import ContactMe from './components/ContactMe.vue'

const routes = [
  {
    path: '/',
    redirect: '/digital-twin'
  },
  {
    path: '/digital-twin',
    name: 'DigitalTwin',
    component: DigitalTwin
  },
  {
    path: '/about-me',
    name: 'AboutMe',
    component: AboutMe
  },
  {
    path: '/contact',
    name: 'ContactMe',
    component: ContactMe
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
<template>
  <div class="about-me">
    <h1>About Me</h1>
    <p>I'm Kuizong Wu (Vincent). I'm currently a Machine Learning Engineer with a big passion for all things AI. My journey into the world of AI really took off when GPT-4 was released, and ever since, I've been hooked on exploring the latest in LLMs and computer vision.</p>
    <p>I'm the kind of person who loves getting hands-on and diving into projects right away. I believe in starting fast, experimenting, and improving along the way. Whether it's tweaking algorithms or building scalable systems, I enjoy the process of turning ideas into something real and impactful.</p>
    <p>When I'm not busy with code, I'm probably reading up on the latest AI papers or figuring out new ways to optimize things. I work with Python, Docker, and AWS, and I'm fluent in both English and Mandarin. Always up for a new challenge or collaboration!</p>
    <br>
    <p class="resume-link"><u><a href="/KuizongWuResumeML.pdf" target="_blank">View My Resume</a></u> @ Latest 2024</p>
  </div>

</template>

<script>
export default {
  name: 'AboutMe'
}
</script>

<style scoped>
.about-me {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 80vh;
  padding: 60px 20px;
  box-sizing: border-box;
  max-width: 800px;
  margin: 0 auto;
}
.resume-link{
  width: 100%;
}

.resume-link a {
  text-decoration: none;
  color: black; /* Customize the link color */
  margin-left: 6vw;
}

.resume-link a:hover {
  text-decoration: underline;
}
</style>